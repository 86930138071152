<template>
    <div>
        <div v-if="showMap" class="apply-map" :id="`map`"></div>
        <div v-if="showMap" class="apply-app-search">
            <van-search style="flex:1" :id="`mapInput`" v-model="contact_addressF" placeholder="请输入搜索关键词" />
            <van-button @click="mapConfirm" size="small" type="primary">确认</van-button>
            <van-button @click="mapcancel" style="margin:0 10px" size="small">取消</van-button>
        </div>
        <div v-show="!showMap"  class="apply-app">
            <van-form @submit="save">
                <van-field 
                    :rules="[{ required: true, message: '请选择' }]"
                    :name="app_avatars"
                    label="机构LOGO"
                    required
                    >
                    <template #input>
                        <van-uploader :deletable="false" :after-read="e=>afterRead(e)" v-model="app_avatar_list" />
                    </template>
                </van-field>
                <van-field required :rules="[{ required: true, message: '请输入机构名称' }]" v-model="app_name" label="机构名称" placeholder="请输入机构名称" />
                <van-field
                    v-model="app_description"
                    rows="2"
                    autosize
                    label="机构简介"
                    type="textarea"
                    maxlength="100"
                    placeholder="请输入机构简介"
                    show-word-limit
                />
                <van-field required :rules="[{ required: true, message: '请输入联系姓名' }]" v-model="contact_name" label="联系姓名" placeholder="请输入联系姓名" />
                <van-field
                    v-model="contact_cellphone"
                    center
                    clearable
                    required
                    label="联系电话"
                    placeholder="请输入联系电话"
                    :rules="[{ validator, required: true, message: '请输入正确的联系电话' }]"
                    >
                    <!-- <template #button>
                        <van-button class="apply-app-time" v-if="showTime" size="small" type="primary" native-type="button">{{times}}</van-button>
                        <van-button @click="toSend" v-else size="small" type="primary" native-type="button">发送验证码</van-button>
                    </template> -->
                </van-field>
                <!-- <van-field required :rules="[{ required: true, message: '请输入验证码' }]" v-model="value" label="验证码" placeholder="请输入验证码" /> -->
                <!-- <van-field
                    v-model="address"
                    readonly
                    clickable
                    name="area"
                    label="地区选择"
                    placeholder="点击选择省市区"
                    @click="showArea = true"
                    required
                    :rules="[{ required: true, message: '选择省市区' }]"
                /> -->
                <van-field @click="toShowMap" required :rules="[{ required: true, message: '请输入详细地址' }]" v-model="contact_address" label="详细地址" placeholder="请输入详细地址">
                    <template #button>
                        <van-icon @click="toShowMap" name="location-o" />
                    </template>
                </van-field>
                <div class="apply-app-checkbox">
                    <van-checkbox v-model="checked" icon-size="14px" checked-color="#00cca2">
                        我已同意<span @click.stop="toPath" style="color:#00cca2">《微点云助教软件使用条款和隐私政策》</span>
                    </van-checkbox>
                </div>
                <div style="margin: 16px">
                    <van-button :disabled="!checked" round block type="primary" native-type="submit">
                    提交
                    </van-button>
                </div>
                <div class="apply-app-tel">
                    <a :href="'tel:'+tel">联系咨询电话：185-0219-9809</a>
                </div>
            </van-form>
        </div>
        <van-popup v-model="showArea" position="bottom">
            <van-area
                :area-list="areaList"
                @confirm="onConfirm"
                @cancel="showArea = false"
            />
        </van-popup>
        <van-popup v-model="isAgreement" closeable close-icon="close" round position="bottom" :style="{ height: '500px',overflow: 'auto'}">
            <div class="agreement ql-editor" v-html="agreementHtml"></div>
        </van-popup>
    </div>
</template>

<script>
    import 'quill/dist/quill.core.css'
    import 'quill/dist/quill.snow.css'
    import 'quill/dist/quill.bubble.css'
    import { Area,Form,Field,Popup,Search,Checkbox,Uploader} from 'vant'
    import { lazyAMapApiLoaderInstance } from 'vue-amap'
    import areaList from '@/common/area'
    import upload from '@/utils/upload'
    import { isPhone } from '@/common/validate'
    export default {
        name:'apply',
        data() {
            return {
                value:'',
                address:'',
                app_name: '',
                app_avatar:'',
                app_avatars:'',
                app_avatar_list:[],
                app_description: '',
                contact_name:'',
                contact_cellphone:'',
                contact_address:'',
                studio_lat:'',
                studio_lng:'',
                contact_addressF:'',
                studio_latF:'',
                studio_lngF:'',
                province:'',
                city:'',
                district:'',
                checked:false,
                showMap:false,
                showTime:false,
                showArea:false,
                isAgreement:false,
                times:60,
                areaList,
                agreementHtml:'',
                tel:'18502199809'
            }
        },
        components: {
            [Area.name]:Area,
            [Form.name]:Form,
            [Field.name]:Field,
            [Popup.name]:Popup,
            [Search.name]:Search,
            [Uploader.name]:Uploader,
            [Checkbox.name]:Checkbox,
        },
        created () {
            this.getAgreement()
        },
        methods: {
            async getAgreement(){
                await this.$api.apply_terms()
                .then(res=>{
                    this.agreementHtml = res.data.terms
                })
            },
            save() {
                let obj = {
                    app_avatar:this.app_avatar,
                    app_name:this.app_name,
                    app_description:this.app_description,
                    contact_name:this.contact_name,
                    contact_cellphone:this.contact_cellphone,
                    province:this.province,
                    city:this.city,
                    district:this.district,
                    contact_address:this.contact_address,
                    studio_lat:this.studio_lat,
                    studio_lng:this.studio_lng,
                }
                this.$toast.loading({
                    message: '提交中...',
                    forbidClick: true,
                })
                this.$api.apply_index(obj)
                .then(res=>{
                    this.$router.push({path:'/apply/applySuccess'})
                })
            },
            toSend(){
                this.showTime = true
                let that = this
                let interval = setInterval(()=>{
                    that.times = --that.times
                    if(that.times == 0){
                        clearInterval(interval)
                        that.showTime = false
                        that.times = 60
                    }
                },1000)
            },
            onConfirm(e){
                this.address = `${e[0].name}${e[1].name}${e[2].name}`
                this.province = e[0].name
                this.city = e[1].name
                this.district = e[2].name
                this.showArea = false
            },
            toShowMap(){
                this.showMap = true
                this.$nextTick(()=>{
                    this.getLocation()
                })
            },
            mapConfirm(){
                this.showMap = false
                this.studio_lat = this.studio_latF
                this.studio_lng = this.studio_lngF
                this.contact_address = this.contact_addressF
                this.studio_latF = ''
                this.studio_lngF = ''
                this.contact_addressF = ''
            },
            mapcancel(){
                this.showMap = false
                this.studio_latF = ''
                this.studio_lngF = ''
                this.contact_addressF = ''
            },
            getLocation(){
                let that = this
                this.isMap = true
                lazyAMapApiLoaderInstance.load().then(() => {
                    const geolocation = new AMap.Geolocation({
                        enableHighAccuracy: true,
                        timeout: 100,
                        zoomToAccuracy: true,     
                        buttonPosition: 'RB'
                    })
                    const autocomplete = new AMap.Autocomplete({
                        input: `mapInput`
                    })
                    geolocation.getCurrentPosition()
                    AMap.event.addListener(geolocation, 'complete', (a)=>{
                        let pos = [a.position.lng,a.position.lat]
                        const map = new AMap.Map(`map`, {
                            resizeEnable: true,
                            center:pos
                        })
                        const marker = new AMap.Marker({
                            position: pos,
                            cursor: 'move',
                        });
                        map.setZoom(16)
                        marker.setMap(map)
                        var clickHandler = function(e) {
                            const geocoder = new AMap.Geocoder({
                                radius: 1000,
                                extensions: 'all'
                            })
                            geocoder.getAddress(e.lnglat, (status, result) => {
                                if (status === 'complete' && result.info === 'OK') {
                                    that.studio_latF = e.lnglat.lat
                                    that.studio_lngF = e.lnglat.lng
                                    that.contact_addressF = result.regeocode.formattedAddress
                                    that.province = result.regeocode.addressComponent.province
                                    that.city = result.regeocode.addressComponent.city?result.regeocode.addressComponent.city:result.regeocode.addressComponent.province
                                    that.district = result.regeocode.addressComponent.district
                                    marker.setPosition([e.lnglat.lng,e.lnglat.lat])
                                    console.log(result,'result')
                                }
                            })
                        }
                        // 绑定事件
                        map.on('click', clickHandler);
                        // 拖放地址
                        AMap.event.addListener(marker, 'dragend', e => {})
                        AMap.event.addListener(autocomplete, 'select', (e,res) => {
                            that.contact_addressF = e.poi.name
                            that.studio_latF = e.poi.location.lat
                            that.studio_lngF = e.poi.location.lng
                            const geocoder = new AMap.Geocoder({
                                radius: 1000,
                                extensions: 'all'
                            })
                            geocoder.getAddress(e.poi.location, (status, result) => {
                                that.province = result.regeocode.addressComponent.province
                                that.city = result.regeocode.addressComponent.city?result.regeocode.addressComponent.city:result.regeocode.addressComponent.province
                                that.district = result.regeocode.addressComponent.district
                            })
                            map.setZoom(15)
                            map.setCenter(e.poi.location)
                            marker.setPosition(e.poi.location)
                            console.log(e,res,'e1')
                        })
                    })
                    AMap.event.addListener(geolocation, 'error', (a)=>{
                        const map = new AMap.Map(`map`, {
                            resizeEnable: true
                        })
                        const marker = new AMap.Marker({
                            cursor: 'move',
                        });
                        map.setZoom(16)
                        marker.setMap(map)
                        var clickHandler = function(e) {
                            const geocoder = new AMap.Geocoder({
                                radius: 1000,
                                extensions: 'all'
                            })
                            geocoder.getAddress(e.lnglat, (status, result) => {
                                if (status === 'complete' && result.info === 'OK') {
                                    that.studio_latF = e.lnglat.lat
                                    that.studio_lngF = e.lnglat.lng
                                    that.contact_addressF = result.regeocode.formattedAddress
                                    that.province = result.regeocode.addressComponent.province
                                    that.city = result.regeocode.addressComponent.city?result.regeocode.addressComponent.city:result.regeocode.addressComponent.province
                                    that.district = result.regeocode.addressComponent.district
                                    marker.setPosition([e.lnglat.lng,e.lnglat.lat])
                                    console.log(result,'result')
                                }
                            })
                        }
                        // 绑定事件
                        map.on('click', clickHandler);
                        // 拖放地址
                        AMap.event.addListener(marker, 'dragend', e => {})
                        AMap.event.addListener(autocomplete, 'select', (e,res) => {
                            that.studio_latF = e.poi.location.lat
                            that.studio_lngF = e.poi.location.lng
                            that.contact_addressF = e.poi.name
                            const geocoder = new AMap.Geocoder({
                                radius: 1000,
                                extensions: 'all'
                            })
                            geocoder.getAddress(e.poi.location, (status, result) => {
                                that.province = result.regeocode.addressComponent.province
                                that.city = result.regeocode.addressComponent.city?result.regeocode.addressComponent.city:result.regeocode.addressComponent.province
                                that.district = result.regeocode.addressComponent.district
                            })
                            map.setZoom(15)
                            map.setCenter(e.poi.location)
                            marker.setPosition(e.poi.location)
                            console.log(e,res,'e2')
                        })
                    })
                })
            },
            toPath(){
                this.isAgreement = true
            },
            afterRead(file,name) {
                let that = this
                this.app_avatar_list = [file]
                upload({ file: file.file })
                .then(res => {
                    that.app_avatar = res.fileName
                })
            },
            validator(val) {
                return isPhone(val)
            },
        },
    }
</script>

<style lang="less" scoped>
    .apply-app{
        background: #fff;
        width: 93%;
        margin: 0 auto;
        margin-top: 15px;
        padding: 10px 0;
        border-radius: 3px;
        overflow: hidden;
        &-time{
            width: 78px;
        }
        &-checkbox{
            margin-top: 10px;
            margin-left: 15px;
            font-size: 12px;
        }
        &-tel{
            text-align: center;
            padding: 10px 0;
            color: #969799;
        }
        &-search{
            width: 100%;
            position: fixed;
            top: 0px;
            left: 0px;
            display: flex;
            align-items: center;
            background: #fff;
        }
    }
    .apply-map{
        height: 100vh;
    }
</style>